



































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import ArrowUp16 from '@carbon/icons-vue/es/arrow--up/16'
import ArrowDown16 from '@carbon/icons-vue/es/arrow--down/16'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import PermissionsMixin from '@/mixins/permissionsMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import QuestionItem from '@/partials/components/QuestionItem.vue'
import QuestionsSearch from '@/partials/components/QuestionsSearch.vue'
import CloneAsDraftModal from '@/partials/modals/Exams/Models/CloneAsDraft.vue'

@Component({
  directives: { mask },
  components: {
    ArrowUp16,
    ArrowDown16,
    TrashCan16,
    QuestionItem,
    ValidationObserver,
    ValidationProvider,
    QuestionsSearch,
    CloneAsDraftModal
  }
})
export default class ExamsModelsEdit extends Mixins(PermissionsMixin, FlashMessageMixin) {
  done = true
  form = {
    code: '',
    type: '',
    draft: true,
    navLock: false,
    switchTabs: false,
    seeScore: false,
    randomOrder: false,
    useQuestionTimer: true,
    useExamTimer: true,
    resetable: false,
    timeMin: 30,
    timeMax: 180
  }

  cloneAsDraftModalIsOpened = false

  highlightLevel: Array<string> = []

  questions: Array<Question> = []

  created () {
    axios.get(`exam/${this.$route.params.id}`)
      .then(response => {
        const responseData = camelCaseKeys(response.data.data, { deep: true })

        this.form = {
          code: responseData.code,
          type: responseData.type,
          draft: responseData.draft,
          navLock: responseData.navLock,
          switchTabs: responseData.switchTabs,
          seeScore: responseData.seeScore,
          randomOrder: responseData.randomOrder,
          useQuestionTimer: responseData.useQuestionTimer,
          useExamTimer: responseData.useExamTimer,
          resetable: responseData.resetable,
          timeMin: responseData.timeMin,
          timeMax: responseData.timeMax
        }

        this.questions = responseData.examQuestions.map((examQuestion: ExamQuestion) => {
          return {
            ...examQuestion.question,
            points: examQuestion.points,
            time: examQuestion.time
          }
        })
      })
  }

  get questionsStatuses () {
    const statuses = this.questions.reduce((acc, question) => {
      if (question.level < 26) acc.easy++
      else if (question.level < 75) acc.medium++
      else { acc.hard++ }

      acc.time += question.time
      return acc
    }, { easy: 0, medium: 0, hard: 0, time: 0 })

    statuses.time = Math.floor(statuses.time / 60)

    return statuses
  }

  questionLevelIsInFilter (level: number) {
    const levelStringToInterval: Record<string, any> = {
      easy: {
        start: 0,
        end: 25
      },
      medium: {
        start: 26,
        end: 74
      },
      hard: {
        start: 75,
        end: 100
      }
    }

    if (this.highlightLevel.length === 0 || this.highlightLevel.length === 3) {
      return true
    } else {
      const isInIntervals = this.highlightLevel.some(hLevel => {
        const levelInterval = levelStringToInterval[hLevel]

        if (level >= levelInterval.start && level <= levelInterval.end) {
          return true
        } else {
          return false
        }
      })

      return isInIntervals
    }
  }

  async redirectToDraft () {
    await this.toggleModal('cloneAsDraftModalIsOpened')
    this.$router.push({ name: 'ExamsDraftsEdit', params: { id: this.$route.params.id } }, () => {
      this.setFlashMessage({ message: 'Modelo clonado com sucesso.', isSuccess: true })
    })
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }
}
