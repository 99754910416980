





























import { Component, Mixins, Prop } from 'vue-property-decorator'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import axios from 'axios'
import { ValidationObserver } from 'vee-validate'

import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

@Component({
  components: {
    ValidationObserver,
    PasswordConfirmField
  }
})
export default class CloneAsDraft extends Mixins(FlashMessageMixin) {
  @Prop({ default: false }) visible!: boolean
  @Prop() questionId!: ''

  form: Record<string, any>= {
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  closeModal () {
    this.$emit('close')
  }

  async submit () {
    axios.patch(`exam/${this.$route.params.id}/clone`, this.form)
      .then(() => {
        this.$emit('submitted')
      })
      .catch(err => this.handleSubmitError(err))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }
}
